<template>
  <standard-layout>
    <template v-slot:heading>
      <h1>Bezahlung</h1>
    </template>
    <template v-slot:main>
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-6" v-if="reservationCart">Deine Buchung</div>
            <div class="col-6" v-else>Dein Gutschein</div>
            <div v-if="reservationCart" class="col-6 text-right">
              <button class="btn btn-sm btn-outline-light p-1"
                      @click="$router.push({name: 'editReservation'})">
                <font-awesome-icon icon="edit"/>
                Ändern
              </button>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <cart-component :cart="cart"/>
        </div>
      </div>
      <div v-if="cart && cart.id && cart.positions.length > 0">
        <div class="row mt-2">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                {{ cart.voucherCart ? 'Rabattcode einlösen' : 'Gutschein einlösen'}}
              </div>
              <div class="card-body">
                <div class="row"
                     v-for="voucher in cart.appliedVouchers"
                     :key="voucher.code">
                  <div class="col-12">
                    Eingelöst: <span class="font-weight-bold">{{ voucher.code }}</span>
                    <span class="text-danger ml-1" @click="removeVoucherFromCart(voucher.code)"><font-awesome-icon
                        icon="minus-circle"/></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <input id="voucherInput"
                           class="form-control"
                           type="text"
                           :placeholder="cart.voucherCart ? 'Rabattcode' : 'Gutschein-Code'"
                           v-model="voucherCode">
                  </div>
                  <div class="col-4">
                    <button class="btn btn-primary"
                            @click="useVoucher">
                      Einlösen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="customer">
          <div class="card mt-2">
            <div class="card-header">
              <div class="row">
                <div class="col-6 text-nowrap">Rechnungsadresse</div>
                <div class="col-6 text-right">
                  <button class="btn btn-sm btn-outline-light p-1"
                          @click="$router.push({name: 'confirmBooking', params: {cart: cart.id}})">
                    <font-awesome-icon icon="edit"/>
                    Ändern
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <div>{{ customer.firstName }} {{ customer.lastName }}</div>
                  <div v-if="customer.company">{{ customer.company }}</div>
                  <div>{{ customer.streetAndNumber }}</div>
                  <div>{{ customer.zip }} {{ customer.city }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="cart.totalGrossPrice > 0">
          <div class="row mt-3" v-if="!alreadyPaid">
            <div class="col-12 h2 text-center">Deine Bezahlmöglichkeiten</div>
            <div class="offset-2 col-8">
              <div id="paypal-button-container" class="h-100"></div>
            </div>
          </div>
          <div class="row mt-3" v-else>
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <span class="text-white">Bereits bezahlt</span>
                </div>
                <div class="card-body text-primary">
                  Du hast diesen Einkaufswagen bereits am {{ cart.order.createdTime | date }} bezahlt.<br/>
                  Die Bestellnummer lautet {{ cart.order.number }}.<br/>
                  <span v-if="cart.reservationNumber">Hier kannst Du Deine Reservierung einsehen:
                  <router-link :to="{name: 'viewReservation', params: {id: cart.reservationNumber}}">
                    Reservierung {{ cart.reservationNumber }}
                  </router-link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 text-center">
            <cld-image public-id="ssl" height="50">
              <cld-transformation quality="auto:low"/>
              <cld-transformation width="300" crop="scale"/>
            </cld-image>
            <cld-image public-id="payment_trust" height="50">
              <cld-transformation quality="auto:low"/>
              <cld-transformation width="400" crop="scale"/>
            </cld-image>
          </div>
        </div>
        <div v-show="cart.totalGrossPrice === 0"
             class="row mt-3">
          <div class="col-12 text-center">
            <p>Diese Bestellung muss nicht bezahlt werden.</p>
            <p>
              <button class="btn btn-primary" @click="checkoutWithoutPayment">Bestellung absenden</button>
            </p>
          </div>
        </div>
      </div>
    </template>
  </standard-layout>
</template>

<script>
import {loadScript} from '@paypal/paypal-js';
import {mapActions, mapGetters} from "vuex";
import CartComponent from "@/components/checkout/CartComponent";

export default {
  components: {CartComponent},
  props: {
    cartId: String,
  },
  data() {
    return {
      voucherCode: ''
    }
  },
  computed: {
    ...mapGetters(['cart']),
    alreadyPaid() {
      return this.cart?.state === 'CHECKED_OUT'
    },
    customer() {
      return this.cart?.customer
    },
    reservationCart() {
      return this.cart?.positions.findIndex(p => p.article.product.category === 'RES') >= 0
    }
  },
  methods: {
    ...mapActions(['initCart', 'updateCart', 'addVoucherToCart', 'removeVoucherFromCart', 'startLoading', 'stopLoading']),
    async loadCart() {
      await this.initCart(this.cartId).then((cart) => {
        const purchaseType = cart.reservationCart ? 'reservation' : (cart.voucherCart ? 'voucher' : 'misc')
        this.$gtag.event(purchaseType + '_checkout', {})
      }).catch(error => this.handleError(error))
    },
    async loadPaypalComponents() {
      this.startLoading('Einen Moment bitte...')
      try {
        await this.initCart(this.cartId)
        const payPalSDK = await loadScript({
          'client-id': process.env.VUE_APP_SPABOOT_PAYPAL_CLIENT_ID,
          currency: 'EUR',
          intent: 'authorize',
        })
        const vm = this
        await payPalSDK.Buttons({
          async createOrder() {
            vm.updateCart(await vm.$api.post('/checkout', {
              cartId: vm.cartId,
              paymentProcessor: 'payPalPaymentProcessor'
            }).then(response => response.data)
                .catch(err => {
                  console.log(err.response)
                  if (err.response?.data.code === 50003) {
                    vm.showError("Es scheint etwas von einem vergangenen Bezahlprozess übrig geblieben. Bitte kontaktiere uns umgehend.")
                  } else
                    vm.handleError(err)
                }))
            return vm.cart.order.paymentReference
          },
          async onApprove(data) {
            vm.startLoading('Verarbeite Bestellung. Bitte warten.')
            try {
              await vm.$api.put('/checkout/authorizations', {...data, paymentProcessor: 'payPalPaymentProcessor'})
                  .then((response) => {
                    const order = response.data
                    if (order.state === 'toBeFulfilled') {
                      vm.routeToOrder(order.id)
                    } else
                      vm.showInfo('Die Bezahlung der Bestellung konnte nicht abgeschlossen werden. Bitte versuche es erneut oder melde Dich bei uns!')
                  }).catch(error => vm.handleError(error))
            } finally {
              vm.stopLoading()
            }
          },
          async onCancel() {
            await vm.loadCart()
          }
        }).render('#paypal-button-container')
      } finally {
        this.stopLoading()
      }
    },
    useVoucher() {
      this.addVoucherToCart(this.voucherCode)
          .then(() => this.voucherCode = undefined)
          .catch(error => this.handleError(error));
    },
    checkoutWithoutPayment() {
      this.startLoading("Verarbeite Bestellung ...")
      this.$api.put('/checkout/authorizations', {
        cartId: this.cart.id
      }).then(response => {
        const order = response.data
        this.routeToOrder(order.id)
      }).catch(error => this.handleError(error))
          .finally(() => this.stopLoading())
    },
    routeToOrder(orderId) {
      this.$router.push({name: 'viewOrder', params: {orderId, justConverted: 'true'}})
    }
  },
  watch: {
    cartId: {
      immediate: true,
      handler(newVal) {
        if(newVal != null)
          this.loadCart()
      }
    },
  },
  activated() {
    this.loadCart()
    this.loadPaypalComponents()
  }
}
</script>
