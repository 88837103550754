<template>
  <div :id="lastModified">
    <table v-if="cartValid" class="table">
      <tr v-for="position in cart.positions"
          :key="'pos_' + position.id">
        <td class="text-wrap w-100">
          {{ position.article.product.name }}
          <p class="text-secondary" v-if="position.article.product.category === 'RES'">
            {{cart.startTime | dateTime }}<br/>
            {{position.article.product.duration}} Stunden<br/>
            {{position.article.product.attendees}} Teilnehmer</p>
          <p class="text-secondary" v-if="position.article.product.productVoucher">
            {{ position.article.product.duration }} Stunden |
            Teilnehmer:
            {{ position.article.product.attendees }}
            <span v-if="position.attendees > position.article.product.attendees"
                  class="text-success font-weight-bolder">
              +{{ position.attendees - position.article.product.attendees }}
            </span>
          </p>
          <p class="text-secondary" v-if="position.article.product.valueVoucher">
            im Wert von {{ position.priceAdd }}€
          </p>
        </td>
        <td class="text-right d-none d-sm-table-cell">
          <span v-if="!position.article.product.valueVoucher">
            {{ position.article.grossPrice | price(true) }}</span>
        </td>
        <td class="text-right">{{ position.quantity }}{{ position.article.product | quantityUnit }}</td>
        <td class="text-right">
          <div class="font-weight-bold" :class="{'text-muted': position.grossDiscount > 0}">
            {{ position.totalGrossPrice - position.priceAdd | price(true) }}
          </div>
          <div v-if="position.grossDiscount > 0" class="text-muted small">(-{{
              position.grossDiscount | price(true)
            }})
          </div>
          <div v-if="position.grossDiscount > 0" class="font-weight-bold">{{
              position.totalGrossPrice | price(true)
            }}
          </div>
          <div v-if="position.priceAdd && !position.article.product.valueVoucher"
               class="text-success font-weight-bolder">
            +{{ position.priceAdd | price(2) }}
          </div>
        </td>
      </tr>
      <tr v-if="cart.grossDiscount > 0">
        <td colspan="2" class="d-sm-none text-right pb-0 small">
          <div v-for="voucher in cart.appliedVouchers" :key="'v_' + voucher.code">
            Gutschein {{ voucher.code }}
          </div>
        </td>
        <td colspan="3" class="d-none d-sm-table-cell text-right pb-0 small">
          <div v-for="voucher in cart.appliedVouchers" :key="'v_' + voucher.code">
            Gutschein {{ voucher.code }}
          </div>
        </td>
        <td class="text-muted small">
          {{ -cart.grossDiscount | price(true) }}
        </td>
      </tr>
      <tr>
        <td colspan="2" class="d-sm-none text-right pb-0">Total</td>
        <td colspan="3" class="d-none d-sm-table-cell text-right pb-0">Total</td>
        <td class="text-right font-weight-bolder pb-0">{{ cart.totalGrossPrice | price(true) }}</td>
      </tr>
      <tr class="d-sm-none small text-muted py-0">
        <td colspan="2" class="text-right py-0 my-0 border-0">
          enthaltene MwSt.
        </td>
        <td colspan="3" class="d-none d-sm-table-cell text-right py-0 my-0 border-0">
          enthaltene MwSt.
        </td>
        <td class="text-right py-0 border-0">
          {{ cart.totalVat | price(true) }}
        </td>
      </tr>
    </table>
    <div v-else
         class="row">
      <div class="col-12 mx-2 my-2 text-center">
        <div>Der angegebene Warenkorb ist nicht mehr gültig.</div>
        <router-link :to="{name: 'home'}">
          <button class="btn btn-primary mt-2">OK</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cart: Object
  },
  computed: {
    lastModified() {
      if (!this.cart?.lastModified)
        return Date.now().valueOf()
      return this.cart.lastModified
    },
    cartValid() {
      return this.cart?.positions?.length > 0
    }
  }
}
</script>
